import { Env, envMerge, sharedDetails } from '@env/environment.types'

export const environment: Env.Variables = envMerge({
    productionMode: true,
    name: Env.Name.Acceptance,

    api: sharedDetails<Env.ApiDetails>({
        clientId: '1',
        clientSecret: 'NJpLGyXBTbGaDG15aYCdigVBDtEjSy040STaPv2n',
        schema: 'https',
        hostname: 'api.acc.joinprogram.pwstaging.tech',
        graphqlPath: 'graphql',
    }),

    pubsub: sharedDetails<Env.PubsubDetails>({
        schema: 'wss',
        hostname: 'v2.pubsub.acc.joinprogram.pwstaging.tech',
        graphqlPath: 'graphql',
    }),
})
