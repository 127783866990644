import { clone } from 'ramda'

/**
 * Merges environment specific information with shared info.
 */
export function envMerge(vars: Omit<Env.Variables, 'apiAcceptedVersionRange' | 'helpEmail'>): Env.Variables {
    return {
        helpEmail: 'helpdesk@joinprogram.com',
        apiAcceptedVersionRange: '>=6.0.0 <7.0.0',
        ...vars,
    }
}

/**
 * Creates a {@link Env.PerCountry per-country record} with the given value for all of them.
 */
export function sharedDetails<T>(value: T): Env.PerCountry<T> {
    return {
        nl: clone(value),
        de: clone(value),
    }
}

export namespace Env {

    export type CountryCode = 'nl' | 'de'

    export type PerCountry<T> = { [K in CountryCode]: T }

    export enum Name {
        Development = 'development',
        Test = 'test',
        Acceptance = 'acceptance',
        Production = 'production',
    }

    /**
     * The www details and credentials of a particular API instance (by country)
     */
    export interface ApiDetails {
        clientId: string
        clientSecret: string
        schema: 'http' | 'https'
        hostname: string
        graphqlPath: string
    }

    export interface PubsubDetails {
        schema: 'ws' | 'wss'
        hostname: string
        graphqlPath: string
    }

    export interface Variables {

        /**
         * Determines whether to run the application in production mode for this environment. If true,
         * Angular debugging features are disabled, and the GraphQL connection is run in default production settings.
         */
        productionMode: boolean
        name: Name

        /**
         * Enter a valid semver version range. This range is matched against the version that the API identifies by.
         * Usually the app requires one specific major version, such as ">= 1.5.0", "2.x", "3.x", etc...
         * Sometimes, during development this may be a larger range, such as ">= 1.4.1 || 2.x" when the app does not
         * require features introduced after the lower bound, and is already compatible with any breaking change
         * introduced in the entire range.
         */
        apiAcceptedVersionRange: string

        /**
         * The main GraphQL API URLs and credentials by country of deployment, used for queries and mutations.
         */
        api: PerCountry<ApiDetails>

        /**
         * The pubsub GraphQL API URL and credentials, used for subscriptions.
         */
        pubsub: PerCountry<PubsubDetails>

        /**
         * The email address to which users can send help requests.
         */
        helpEmail: string
    }
}
